/**
 * @generated SignedSource<<b98cf408527603ffce56c18bf5a43a79>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EntityKind = "ORGANIZATION" | "USER";
export type EntityScaffoldQuery$variables = {
  username: string;
};
export type EntityScaffoldQuery$data = {
  readonly entityByUsername: {
    readonly bio: string | null | undefined;
    readonly createdAt: string;
    readonly displayName: string;
    readonly github: string | null | undefined;
    readonly imageThumbnail: any | null | undefined;
    readonly jobTitle?: string | null | undefined;
    readonly karma?: number;
    readonly kind: EntityKind;
    readonly linkedin: string | null | undefined;
    readonly location: string | null | undefined;
    readonly organization?: string | null | undefined;
    readonly username: string;
    readonly viewerCanConfigureNotifications?: boolean;
    readonly viewerCanEdit?: boolean;
    readonly website: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"EntityHelmetFragment" | "EntityProfilePicFragment">;
  } | null | undefined;
};
export type EntityScaffoldQuery = {
  response: EntityScaffoldQuery$data;
  variables: EntityScaffoldQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "username"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "username",
    "variableName": "username"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "location",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "linkedin",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "github",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "website",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bio",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageThumbnail",
  "storageKey": null
},
v12 = [
  {
    "kind": "Literal",
    "name": "action",
    "value": "UPDATE_USER"
  }
],
v13 = {
  "alias": "viewerCanEdit",
  "args": (v12/*: any*/),
  "kind": "ScalarField",
  "name": "viewerCan",
  "storageKey": "viewerCan(action:\"UPDATE_USER\")"
},
v14 = {
  "alias": "viewerCanConfigureNotifications",
  "args": (v12/*: any*/),
  "kind": "ScalarField",
  "name": "viewerCan",
  "storageKey": "viewerCan(action:\"UPDATE_USER\")"
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "organization",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jobTitle",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "karma",
  "storageKey": null
},
v18 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": "viewerCanEdit",
      "args": [
        {
          "kind": "Literal",
          "name": "action",
          "value": "UPDATE_ORGANIZATION"
        }
      ],
      "kind": "ScalarField",
      "name": "viewerCan",
      "storageKey": "viewerCan(action:\"UPDATE_ORGANIZATION\")"
    }
  ],
  "type": "Organization",
  "abstractKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EntityScaffoldQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "entityByUsername",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          {
            "args": [
              {
                "kind": "Literal",
                "name": "thumbnail",
                "value": false
              }
            ],
            "kind": "FragmentSpread",
            "name": "EntityProfilePicFragment"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/)
            ],
            "type": "User",
            "abstractKey": null
          },
          (v18/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EntityHelmetFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EntityScaffoldQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "entityByUsername",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isEntity"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "image",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "last",
                    "value": 10
                  }
                ],
                "concreteType": "CommentConnection",
                "kind": "LinkedField",
                "name": "comments",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Comment",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "content",
                        "storageKey": null
                      },
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "votes",
                        "storageKey": null
                      },
                      (v19/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "comments(last:10)"
              }
            ],
            "type": "User",
            "abstractKey": null
          },
          (v18/*: any*/),
          (v19/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "702c0e195bfdf1848c6e7fed3f1228bc",
    "id": null,
    "metadata": {},
    "name": "EntityScaffoldQuery",
    "operationKind": "query",
    "text": "query EntityScaffoldQuery(\n  $username: String!\n) {\n  entityByUsername(username: $username) {\n    __typename\n    kind\n    displayName\n    location\n    username\n    linkedin\n    github\n    website\n    bio\n    createdAt\n    imageThumbnail\n    ...EntityProfilePicFragment_db32b\n    ... on User {\n      viewerCanEdit: viewerCan(action: UPDATE_USER)\n      viewerCanConfigureNotifications: viewerCan(action: UPDATE_USER)\n      organization\n      jobTitle\n      karma\n    }\n    ... on Organization {\n      viewerCanEdit: viewerCan(action: UPDATE_ORGANIZATION)\n    }\n    ...EntityHelmetFragment\n    id\n  }\n}\n\nfragment EntityHelmetFragment on Entity {\n  __isEntity: __typename\n  id\n  kind\n  displayName\n  location\n  username\n  linkedin\n  github\n  website\n  bio\n  createdAt\n  image\n  imageThumbnail\n  ... on User {\n    jobTitle\n    comments(last: 10) {\n      nodes {\n        content\n        createdAt\n        votes\n        id\n      }\n    }\n  }\n}\n\nfragment EntityProfilePicFragment_db32b on Entity {\n  __isEntity: __typename\n  displayName\n  image\n}\n"
  }
};
})();

(node as any).hash = "35c86526a1302bd3ca335a63e9771339";

export default node;
